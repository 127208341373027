.sliderStyle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 125px;
}

/*the slider bar*/
.sliderStyle {
  -webkit-appearance: none;
  appearance: none;
  width: 120px;
  height: 8px;
  background: #E4E7EC;
  border-radius: 4px;
  outline: none;
  margin: 10px 0;
  position: relative;
}

/*the slider bar thumb*/
.sliderStyle::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 2px solid #667085;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

/*the slider bar thumb click effect*/
.sliderStyle::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.sliderStyle::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.sliderStyle::-moz-range-thumb:hover {
  transform: scale(1.2);
}

/*the value text UI ==> NOT using now*/
.slider-value {
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
}
