/*************** 1.1 The search bar area for search the issue number ************************/
.searchBox {
  margin: 0;
  display: flex;
  width: 100%;
  height: 54px;
  padding-top: 10px;
  background-color: white;
  align-items: center;
}

.searchBox p {
  color: var(--Neutral-Foreground-2-Rest, #424242);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-right: 16px;
}

/*used svg to implement the searching process circle bar effect*/
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tabs-container {
  display: flex;
  gap: var(--space-xs); /* adjust the gap between tabs */
  justify-content: flex-start; /* align the tabs horizontally */
  padding: var(--space-md) 0;
  align-self: stretch;
  flex: 1;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
}

@keyframes progress {
  0% {
    stroke-dashoffset: 125.6;
    /*default status of start it*/
  }

  100% {
    stroke-dashoffset: 0;
    /*the final statu ==> finished searching*/
  }
}

/*************** 1.2 the notification of search bar input to display error search including empty and can not find, background ************************/
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/*the custom modal to display error search including empty and can not find, whithe modal window*/
.modal-content {
  background: var(--Neutral-Background-1-Rest, #fff);
  margin: 15% auto;
  font-family: "Segoe UI";
  padding: 5px;
  border: 1px solid #888;
  width: 80%;
  max-width: 420px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--8, 8px);
  border: 1px solid
    var(--Neutral-Stroke-Transparent-Rest, rgba(255, 255, 255, 0));
  box-shadow:
    0px 32px 64px 0px rgba(0, 0, 0, 0.24),
    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

/*the cancel button in the right-top */
.modal-content .cancelButton {
  position: absolute;
  top: 10px;
  right: 11px;
  cursor: pointer;
  background: transparent;
}

.cancelButton:active {
  background: #e0e0e0;
  transform: scale(0.95);
}

.modal-content h2 {
  font-size: 24px;
  margin-left: 16px;
  font-family: "Segoe UI";
  color: var(--text-color);
  margin-bottom: 12px;
  user-select: none;
  font-weight: bold;
}

.modal-content span {
  font-size: 16px;
  font-family: "Segoe UI";
  font-weight: 400;
  margin-left: 16px;
  user-select: none;
  color: var(--text-color);
}

/*the confirm button in the modal window*/
.modal-content .modal-button {
  background: #da1e28;
  align-self: flex-end;
  color: white;
  font-weight: bold;
  margin-right: 11px;
  margin-top: 16px;
  height: 26px;
  padding: 4px 8px;
  border-radius: var(--radius-xs);
}

.modal-button:active {
  background: #9e161d;
  align-self: flex-end;
}
