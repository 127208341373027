/************************* 2. The specifications area (right): specifications information and other information part *************************/
/************************* 2.1 the Specifications area part *************************/
.specificationsArea {
  width: 100%;
  height: 100%;
}

/*the right format menu button area of module 2*/
.menuListWrapper {
  display: flex;
  height: 44px;
  padding-bottom: 4px;
  align-items: center;
  gap: 6px;
  border-bottom: 2px solid var(--Colors-Border-border-secondary);
  margin-left: 12px;
  margin-right: 12px;
}

.subMenuArea{
  display: flex;
  height: 46px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: var(--color-dark-gray-50);
  margin: 8px 12px;
}

.subMenuTabButtonList{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.borderBottom{
  border-top: 2px solid var(--Colors-Border-border-secondary);
  margin-left: 12px;
  margin-right: 12px;
}

/*the key specification data area format*/
.specificationsFormat {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 252px);
  min-height: calc(100vh - 252px);
  max-height: calc(100vh - 252px);
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
  cursor: default;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 12px;
}

.specificationsFormat::-webkit-scrollbar {
  width: 3px !important;
}

.specificationsFormat::-webkit-scrollbar-track {
  background: white !important;
}

.specificationsFormat::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
  border-radius: 4px;
}