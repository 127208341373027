@import "@carbon/charts/styles.css";
@import "../../App.css";
/*Module 1 (Drive) : This is used for thenode graph page: search bar, graph area, and item information box*/

/**************** 1. The node view graph area including filter bar and bottom bar check color *************************************/
.graph-area {
  height: 100%;
  width: calc(70% - 2px);
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  outline: none;
  background-color: white;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
}

.graph-area.expanded {
  width: calc(50% - 2px);
}

/****************** 2. the component information (right) part **********************/
.inforDetails {
  flex: 1;
  width: calc(30% - 2px);
  height: 100%;
  max-height: calc(100vh - 50px);
  position: relative;
  padding: 0;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  box-sizing: border-box;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  border-left: 1px solid #ccc;
  transition: width 0.3s;
}

.info-details.expanded {
  width: calc(50% - 2px);
}

/* Media query for Large screens */
@media (min-width: 2700px) {
  .graph-area {
    width: 80%;
    float: left;
  }

  .inforDetails {
    width: 20%;
    float: left;
  }

  .graph-area.expanded {
    width: 50%;
    float: left;
  }

  .info-details.expanded {
    width: 50%;
    float: left;
  }
}

/* Media query for medium screens */
@media (min-width: 768px) and (max-width: 2699px) {
  .graph-area {
    width: 70%;
    float: left;
  }

  .inforDetails {
    width: 30%;
    float: left;
  }

  .graph-area.expanded {
    width: 50%;
    float: left;
  }

  .info-details.expanded {
    width: 50%;
    float: left;
  }
}

/* Media query for small screens */
@media (max-width: 767px) {
  .graph-area {
    width: 100%;
  }

  .inforDetails {
    width: 100%;
  }
}
