/*********************   1. The control area: the Highlight Button && the Tab button control open the key specification area   **********************/
.controlArea{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.searchResultMessage{
  color: #101828;
  font-family: var(--font-family-display);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md);
  margin: 0px;
}

.columnSearch {
  display: flex;
  height: 12px;
  padding: 10px 8px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
   
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  background: #fff;
  margin: 8px 12px;
  width: calc(100% - 40px);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* 1.2: The key specification tab button to control open the right information area or not*/
.ToggleButton{
  display: flex;
  padding: 8px var(--font-size-sm);
  align-items: center;
  gap: var(--spacing-md);
  align-self: stretch;
  border-radius: var(--radius-xs);
  border: 1px solid var(--color-gray-300);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  white-space: nowrap;
  margin-left: 12px;
}

.ToggleButton p{
  color: var(--color-gray-800); /* Rebecca want to the color more black in the text */
  text-overflow: ellipsis;
  /* Text md/Regular */
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xs); 
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
}


/*********************   2. The issue List area (left): star button, customize tabel button  **********************/
.sharedItemArea {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #242424;
  align-self: stretch;
  height: 48px;
  margin-top: 12px;
  padding: 8px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border: 1px solid var(--color-gray-200);
  border-bottom: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  position: relative;
}


/* 2.1: the customize table dropdown menu selection item layout */
.filterMenuScrollable::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

.filterMenuScrollable {
  flex: 1;
  overflow-y: auto;
  max-height: 300px;
}

.filterMenuScrollable::-webkit-scrollbar {
  width: 8px;
  padding: 4px;
}

.filterMenuScrollable::-webkit-scrollbar-thumb {
  background: var(--color-gray-200);
  border-radius: 1000px;
}

.filterMenuScrollable::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-200);
}

.filterMenuScrollable::after {
  display: none;
}


/* 2.3: the keyboard feature menu display all the keyboard feature of issue table */
.rightButtonArea{
  display: flex;
  align-items: center;
  gap: 10px;
}

.keyBoardButton{
  display: flex;
  padding: 8px;
  height: 14px;
  width: 22px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-xs);
  background: var(--color-dark-gray-50);
  cursor: pointer;
}

.keyBoardButton:active{
  background: var(--color-dark-gray-200);
}

.keyBoardButton svg{
  width: 24px !important;
  height: 24px !important;
  user-select: pointer;
}

.keyboardDropdownMenu {
  position: absolute;
  right: 220px;
  top: 100%;
  border-radius: 4px;
  min-width: 400px;
  max-width: 400px;
  min-height: 220px;
  max-height: 220px;
  margin-top: 4px;
  padding: 6px;
  border: 1px solid var(--color-gray-200);
  background: var(--color-base-white);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-body);
  z-index: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.keyboardDropdownMenu.show {
  transform: scale(1);
  opacity: 1;
  z-index: 9999;
}

.keyboardDropdownMenu .featureItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-gray-700);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-size-xl); /* 142.857% */
  padding: 6px;
}

.KeyBoardIcon {
  display: flex;
  min-width: 16px;
  width: auto;
  height: 16px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-gray-100);
}

/* 2.5 the edit button of issue table column system */
.editButton {
  background-color: #fff;
  border-radius: 4px;
  padding: 8x 12px;
  cursor: pointer;
  border: 1px solid var(--color-gray-300);
  font-family: var(--font-family-display);
  color: var(--color-gray-700);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); /* 142.857% */
}

.editButton:hover {
  background-color: var(--color-gray-100);
  color: black;
}

.editButton:active {
  background-color: var(--color-gray-200);
  color: black;
}


/**************** 3. the realative issue data table: below the switch bar to disaply all the relative issue details as format*****************/
.shareIssueAreaFormat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.shareQueryDeatils{
    display: flex;
    flex-direction: column;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    margin: 12px;
    border: 1px solid var(--color-gray-200);
    position: relative;
}

.queryArea{
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-200);
}

/* 3.4: the issue data table scroll bar width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-500);
  cursor: pointer;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

/* ************************ 4. The issue detials pop window that user selected similar issue for display  *************************/
.issueDetailsModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  max-width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--Colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10));
  /* Makes the modal container not block clicks */
}

.issueDetailsArea {
  display: flex;
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 730px;
  min-width: 730px;
  max-width: 830px;
  max-height: 75vh;
  background: var(--Neutral-Background-1-Rest, #fff);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Stroke-Transparent-Rest, rgba(255, 255, 255, 0));
  box-shadow:
    0px 32px 64px 0px rgba(0, 0, 0, 0.24),
    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  pointer-events: all;
  cursor: default;
  user-select: text;
}

.issueDetailsArea:active {
  cursor: text;
}

/*the relative issue pop window header tittle area*/
.detailsHeader{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.detailsHeader h2{
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-800);
}

/*the close button of issue details*/
.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  border-radius: 50%;
}

.closeButton:active {
  background: #e0e0e0;
  transform: scale(0.95);
  /* Slight shrink effect */
}

.issueDetailsArea h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 28px;
  color: var(--Neutral-Foreground-2-Rest, #424242);
}

/*the issue details menu switch: details, timeline, and commen */
.realativeIssueDetailsMenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 16px;
  white-space: nowrap;
  gap: 4px;
}

/*the background color was white */
.issueDetailsArea .issueFormat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  align-self: stretch;
  width: 100%;
  gap: 12px;
}

.issueDetailsArea .issueFormatChild {
  display: flex;
  padding: 16px 12px;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #F9FAFB;
  gap: 16px;
}

.issueFormat h3{
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 0px;
}

.issueFormat h2{
  color: var(--color-gray-600);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-display-xs); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 4px;
}

.issueFormat .downloadText{
  color: var(--brand-secondary-700);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-display-xs); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 4px;
  cursor: pointer;
}

.issueDetailsRowDisplay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.issueDetailsColumnDisplay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
   
  white-space: pre-wrap; /* Preserves line breaks in the content */

}

.detailsAreaValue {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding-bottom: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
  white-space: pre-wrap;
}

.areaItem {
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-gray-300);
  padding: 1px 4px;
  white-space: nowrap; /* not change new line*/
  overflow: hidden;
  text-overflow: ellipsis; /* overside, it will hidden and display "..." */
}

.attachmentsArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
  gap: 12px;
  margin-bottom: 12px;
}

.attachmentsArea svg{
  display: flex;
  justify-content: center;
  align-items: center;
}

/*the background color was white */
.issueDetailsArea .issueGrayFormat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  align-self: stretch;
   
  padding: 12px;
  gap: 8px;
  background-color: var(--color-dark-gray-50);
}

.draggableHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: transparent;
  z-index: 1;
}

