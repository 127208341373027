.ModuleCard{
    display: flex;
    min-width: 300px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 4px;
    background: var(--color-dark-gray-50);
    font-family: var(--font-family-display);
}

.iconTitle{
    display: flex;
    align-items: flex-start;
}

.TextArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.title{
    color: var(--color-gray-900);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xl); /* 150% */
}

.context{
    color: var(--color-gray-600);
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-md); /* 150% */
    white-space: nowrap;
}

.actionText {
    color: var(--blue-600);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm); /* 150% */
    cursor: pointer;
}

.actionText:hover {
    color: var(--blue-800);
}



