/*Module 1 (Drive): this is the css file about Concern Flow Stage part*/

/*************** 1. the Basic Details area CSS attrubite ****************/
/*used for the descrbtion area with longer string*/
.basicDetailsItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 24px;
}

/* used for the tittle data (single data one row): Total Investment && Anticipated Warranty Costs and Contribution Costs...*/
.basicDetailsData {
  margin-top: 16px;
  display: flex;
}

/* the area will change 2 - 4 colums based expand or not*/
.basicDetailsDataGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* default is 2 column */
  gap: 18px;
  margin-top: 24px;
}

/*the area expanded, it will shows 4 columns one row*/
.basicDetailsDataGrid.expanded {
  grid-template-columns: repeat(
    4,
    1fr
  ); /* after expand the right area, will display as 4 columns */
}

/*the data tittle */
.itemTittle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--Neutral-Foreground-3-Rest, #616161);
}

/*the data short value */
.itemValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
}

/*the data longer string value default display without collapsed */
.itemStrValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
}

/*used for readme display ==> only display 3 lines*/
.itemStrValue.collapsed {
  /* Style to apply when the content is collapsed */
  max-height: calc(3 * 21px);
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show only 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  transition: max-height 0.3s ease;
}

/*the read more function fots*/
.readMore {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: 20px;
  padding-left: 0px;
  margin-top: 6px;
  color: var(--Secondary-color, #066755);
}

/*each data row including itemTittle, and itemValue or itemStrValue*/
.basicDetailsDataRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

/**************** 2. the Change Action Evaluation area CSS attrubite *******************/
.changeActionButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 32px;
  width: 100%;
}

.changeActionButton label {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.Graphbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 32px;
  color: #066755;
  width: 70px;
  border: 1px solid var(--Secondary-color, #066755);
  border-radius: 4px;
  background: var(--Neutral-Background-1-Rest, #fff);
}

.changeActionButton button:active {
  background-color: #e0e0e0; /* Darker background on click */
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.scenarioMenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
  white-space: nowrap;
}

.scenarioMenu button {
  display: flex;
  align-items: flex-start;
  background-color: var(--Neutral-Background-2-Rest, #fafafa);
  color: var(--text-color);
  border: none;
  height: 32px;
  font-size: 12px;
  padding-top: 6px;
  padding-right: 0;
  padding-left: 0;
  margin-right: 16px;
}

.scenarioMenu button.active {
  color: #066755;
  font-weight: bold;
  border-bottom: 2px solid green;
}

.scenarioMenu button:hover {
  font-weight: bold;
  border-bottom: 2px solid green;
}

/**************** 3. the Business Case Evaluation area CSS attrubite *******************/
.BusinessCaseButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 32px;
  width: 100%;
}

.BusinessCaseButton label {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
}
