@import "../../App.css";

.achieve-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: var(--space-5xl);
    padding-bottom: var(--space-9xl);
}

.achieveContext {
    width: 60%;
    min-width: 1100px;
    max-width: 65%;
    display: flex;
    justify-content: space-between;
}

.InfoSection {
    min-width: calc(50% - 12pxc);
    max-width: calc(50% - 12pxc);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    padding-top: 24px;
}

.InfoSection h1 {
    color: var(--color-gray-900);
    font-family: var(--font-family-display);
    font-size: var(--font-size-display-lg);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-display-lg);
    letter-spacing: -1.8px;
    margin-bottom: 0px;
    margin-top: 24px;
}

.highlight {
    color: var(--brand-secondary-500);
}

.InfoSection p {
    color: var(--color-gray-500);
    font-family: var(--font-family-display);
    font-size: var(--font-size-lg);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-xl);
    margin: 0px;
}

.svgIcon{
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand-secondary-500);
    border-radius: var(--radius-lg);
    margin-top: 24px;
}

.contact .tittle {
    font-weight: bold;
    font-size: var(--font-size-lg);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl); /* 150% */
}

.contact .subTittle {
    font-weight: bold;
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-md);  
    margin-bottom: 12px;
}

.contact .emailText {
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm);  
    text-decoration: none;
    color: var(--brand-secondary-700);
}

.DashboardImage {
    min-width: calc(50% - 12pxc);
    max-width: calc(50% - 12pxc);
    margin-left: 24px;
    background-color: var(--color-dark-gray-50);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    padding: 42px 0px 24px 39px;
    flex: 1 0 0;
}

.DashboardImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
}

.DashboardImage h3{
    color: var(--color-gray-900);
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl);  
    text-decoration: none;
    margin: 0px;
}

.DashboardImage ul {
    padding-left: 24px; 
    margin: 0px;
}

.DashboardImage li {
    margin-bottom: 10px;
    white-space: normal;
    color: var(--color-gray-700);
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-sm);  
}