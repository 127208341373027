@import "../../../App.css";

.button-default {
  background-color: var(--color-white);
  border: none;
  cursor: pointer;
  color: var(--color-gray-700);
  border-radius: 4px;
  height: 24px;
  border: 1px solid var(--color-gray-300);
  padding: 8px 4px;
  display: flex;
  align-items: center;
  font-family: var(--font-family-display);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  gap: 4px;
}

.button-default:hover {
  background: var(--color-gray-100);
}

.button-default.isClicked {
  background: var(--color-gray-100);
}


.button-AI {
  background-color: var(--blue-600);
  border: none;
  cursor: pointer;
  color: var(--color-white);
  border-radius: 4px;
  height: 24px;
  border: 1px solid var(--blue-600);
  padding: 8px 4px;
  display: flex;
  align-items: center;
  font-family: var(--font-family-display);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  gap: 4px;
}

.button-AI:hover {
  border: 1px solid var(--blue-700);
  background: var(--blue-700);
}


