@import "../../../App.css";

.tabs-container {
  display: flex;
  gap: var(--space-xs); /* adjust the gap between tabs */
  justify-content: flex-start; /* align the tabs horizontally */
  padding: var(--space-md) 0;
}

.tab-button {
  background-color: transparent; /* not have background color */
  color: var(--color-gray-500); /* the words color */
  padding: var(--space-md) var(--space-lg);
  border: none;
  width: auto;

  height: 32px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  font-family: var(--font-family-body, Inter); /* Font family */
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  color: var(--color-gray-500);
}

/* The hover status of Tabs button */
.tab-button:hover {
  background-color: var(--color-gray-100); /* the hover color of Tabs button */
}

/* ********************************** 1. The active status of Tabs Button (black) **********************************/
.tab-button--black.active {
  background-color: var(--color-gray-700); /* Background color */
  color: var(--color-white); /* the fonts color of current status */
}

/* current hover color of active status */
.tab-button--black.active:hover {
  background-color: var(
    --color-gray-800
  ); /* Background color of hover status in active button*/
}

.tab-button--black.disabled {
  background-color: transparent; /* Background color */
  color: var(--color-gray-300); /* the fonts color of current status */
  cursor: not-allowed;
}

.tab-button--black.disabled:hover {
  background-color: transparent; /* Background color */
  color: var(--color-gray-300); /* the fonts color of current status */
  cursor: not-allowed;
}

/* ********************************** 2. The active status of Tabs Button (white) **********************************/
.tab-button--white.active {
  background-color: var(--color-white); /* Background color */
  color: var(--color-gray-800); /* the fonts color of current status */
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.tab-button--white.disabled {
  background-color: transparent; /* Background color */
  color: var(--color-gray-500); /* the fonts color of current status */
  cursor: not-allowed;
}

.tab-button--white.disabled:hover {
  background-color: transparent; /* Background color */
  color: var(--color-gray-800); /* the fonts color of current status */
  cursor: not-allowed;
}

/* ********************************** 3. The active status of Tabs Button (Simple) **********************************/
.tab-button--simple.active {
  color: var(--color-gray-800); /* the fonts color of current status */
  border-bottom: 2px solid var(--brand-secondary-400);
  border-radius: 0;
}

.tab-button--simple:hover {
  color: var(--color-gray-800); /* the fonts color of current status */
  background-color: transparent; /* Background color */
  border-bottom: 2px solid #667085;
  border-radius: 0;
}

.tab-button--simple.disabled {
  background-color: transparent; /* Background color */
  color: var(--color-gray-500); /* the fonts color of current status */
  cursor: not-allowed;
}

.tab-button--simple.disabled:hover {
  background-color: transparent; /* Background color */
  color: var(--color-gray-800); /* the fonts color of current status */
  border-bottom: 2px solid #667085;
  cursor: not-allowed;
}