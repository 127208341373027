.commentItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: var(--color-gray-100);
    padding: 8px 12px;
    gap: 4px;
    align-self: stretch;
    font-family: var(--font-family-body);
}

.commentItem P{
    margin: 0;
}

.userNameStyle {
    color: var(--color-gray-900);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-sm);
}

.timeStyle{
    color: var(--color-gray-900);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-xxs);
}

.contentStyle{
    color: var(--color-gray-900);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-sm);
    width: calc(100% - 6px);
    white-space: pre-line;
}

