.sharedOnly {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Colors-Border-border-secondary);
    background: var(--color-base-white);
    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
    font-family: var(--font-family-display);
}

.iconArea{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.titleLayout{
    color: var(--color-gray-700);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm); /* 142.857% */
}

.subText{
    color: var(--color-gray-600);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-sm); /* 142.857% */
}

.contactText{
    color: var(--blue-600);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm); /* 142.857% */
}