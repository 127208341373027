/****************** 2.1 the properties Area: including the information format and button menu *******************/
.propertiesArea {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  padding-bottom: 9px;
  min-height: 70px;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
}

/*the button (<<< && >>>) to expand or reduce the right side format width size*/
.expandbuttonBox {
  color: var(--icon-color);
  background: none;
  border: none;
  margin-left: 0px;
  padding-right: 0px;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999;
  transition: transform 0.3s;
  /* move effect */
}

/*the propertiesArea button menu*/
.buttonPageWrapper {
  position: relative;
  width: 100%;
}

.buttonPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 18px;
  border-bottom: 1px solid var(--line-color);
  white-space: nowrap;
  cursor: grab;
  /*could use mosue grab the menu button area to display if the width is not enough*/
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -webkit-overflow-scrolling: touch;
}

.buttonPage::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.buttonPage:active {
  cursor: grabbing;
  /*could use mosue grab the menu button area to display if the width is not enough*/
}

.buttonPageWrapper:hover .scrollButton {
  opacity: 80%;
}

.scrollButton {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background-color: #e0e0e0;
  border: none;
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  opacity: 50%;
  transition: opacity 0.3s ease;
}

.scrollButton.left {
  left: 0px;
}

.scrollButton.right {
  right: 0px;
}

.buttonPage button {
  display: flex;
  align-items: flex-start;
  background-color: white;
  color: var(--text-color);
  border: none;
  height: 32px;
  font-size: 13px;
  padding-top: 4px;
  padding-right: 0;
  padding-left: 0;
  margin-right: 20px;
  white-space: nowrap;
}

.buttonPage button.active {
  color: #066755;
  font-weight: bold;
  border-bottom: 2px solid var(--Secondary-color, #066755);
}

.buttonPage button:hover {
  border-bottom: 2px solid var(--Secondary-color, #066755);
}

.buttonPage button.activeError {
  color: var(--Status-Danger-Foreground-1-Rest, #b10e1c);
  font-weight: bold;
  border-bottom: 2px solid var(--Status-Danger-Foreground-1-Rest, #b10e1c);
}

/*the information display format*/
.format {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: var(--Neutral-Background-2-Rest, #fafafa);
}

.format .childSide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  width: 100%;
  margin-right: 16px;
  padding: 16px 8px;
  gap: 12px;
}

/*the area expanded, it will shows 4 columns one row*/
.childSide.expanded {
  grid-template-columns: repeat(4, 1fr);
  /* after expand the right area, will display as 4 columns */
}

.format .descriptionChild {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 6px;
  margin-top: 8px;
  margin-bottom: 12px;
  margin-left: 8px;
}

.barChartTittle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.barChartButton {
  background-color: white;
  color: #424242;
  border: none;
  height: 32px;
  margin-right: 16px;
}

.descriptionChild .item,
.childSide .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: var(--text-color);
}

.name {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--Neutral-Foreground-3-Rest, #616161);
}

.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
}

.strValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
  white-space: pre-wrap;
}

/*the right information ==> bar char area*/
.barChart {
  width: 100%;
  margin-top: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

/*the meter chart each row */
.barChart .chartContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 24px;
  margin-top: 8px;
  margin-bottom: 16px;
}

/********************** 2.2 the concern area part *****************************************/
.concernArea {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 82px;
}

.concernArea .printButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 28px;
  margin-right: 8px;
  color: #066755;
  width: 72px;
  border: 1px solid var(--Secondary-color, #066755);
  border-radius: 4px;
  background: var(--Neutral-Background-1-Rest, #fff);
}

.concernArea .printButton:active {
  background-color: #e0e0e0; /* Darker background on click */
  transform: scale(0.95); /* Slightly shrink the button on click */
}

.concernArea h2,
.propertiesArea h2 {
  font-size: 20px;
  font-weight: 600;
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

.concernArea p,
.propertiesArea p {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 0;
}

/*the area including four AccordionItem: General, Change Action Evaluation, Business Case Evaluation and Release Notice*/
.otherPart {
  margin-top: 18px;
  margin-left: 16px;
  margin-right: 16px;
}

/*four AccordionItem area*/
.otherPart .headerArea {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f4f8f8;
  min-height: 36px;
  color: var(--text-color);
  height: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/*four AccordionItem area icon*/
.otherPart .headerArea .icon {
  margin-left: auto;
}

/*four AccordionItem area tittle area*/
.otherPart .headerContainer {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 8px;
  color: var(--text-color);
  justify-content: space-between;
}

.otherPart .headerContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 8px;
}

.otherPart .headerArea.active {
  background-color: var(--Neutral-Background-2-Rest, #fafafa);
}

.otherPart .headerContainer.active {
  background-color: var(--Neutral-Background-3-Rest, #f5f5f5);
}
