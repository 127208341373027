.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-dots-loader {
  position: relative;
  width: 40px;
  height: 40px;
}

.spinner-dots-loader .spinner-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  background-color: var(--brand-secondary-400);
  border-radius: 50%;
  animation: spinner-dots-loader 1s infinite linear;
}

@keyframes spinner-dots-loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} 