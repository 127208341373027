/*the team page container area */
.team-table-container {
  max-width: 60%;
  margin: 32px auto;
  padding: 16px 0px;
  padding-bottom: 0px;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--colors-gray-light-mode-200, #E4E7EC);
  font-family: var(--font-family-display);
}

.team-table-container .teamTitle{
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark-gray-900);
  margin-top: 0px;
  margin-left: 20px;
  margin-bottom: 12px;
}

.team-table-wrapper {
  max-height: 80vh; /* the max height */
  overflow-y: auto; /* allowed the scroll bar to scoll in y-axis */
  overflow-x: hidden; /* not avoid the scroll bar */
}

/*the team data table */
.team-table {
  width: 100%;
  border-collapse: collapse;
  font-size: var(--font-size-sm);
  margin-top: 12px;
}

.team-table th {
  text-align: left;
  padding: 12px 24px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-500);
  background-color: #F9FAFB;
}

.team-table td {
  padding: 12px 21px;
  background-color: #ffffff;
  width: 300px;
  font-weight: var(--font-weight-regular);
  color: var(--color-gray-900);
}

.team-table tr:nth-child(even) td {
  background-color: var(--color-dark-gray-50);
}

.member-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  background-color: #d1d5db;
  border-radius: 50%;
  font-size: var(--font-size-sm);
  margin-bottom: 0px;
  margin-right: var(--font-size-xs);
}

.member-name {
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-900);
}

.groupRole-badge {
  margin-left: 12px;
  padding: 3px 8px;
  font-size: 0.75em;
  color: var(--color-gray-700);
  background-color: var(--color-base-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--radius-xs);
}
