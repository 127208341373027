@import "../../../App.css";

/* General Styles */
.input-container {
  font-family: var(--font-family-display); /* Font family */
}

.input-label {
  font-size: 14px;
  font-weight: 500;
}

.input-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 8px 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: hidden; /* Ensure that overflow is hidden */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
  font-family: var(--font-family-display);
  font-size: var(--font-size-md); /* Font size */
  font-style: normal; /* Font style */
  font-weight: var(--font-weight-regular); /* Font weight */
  line-height: var(--line-height-md); /* Line height */
}

.input-field input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-xs); /* Font size */
  font-style: normal; /* Font style */
  font-weight: var(--font-weight-regular); /* Font weight */
  line-height: var(--line-height-xs); /* Line height */
}

/* 1. General with Gray */
.generalWithGray .input-field {
  border: 1px solid var(--color-gray-300);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-500); /* Gray text color */
}
.generalWithGray .input-hint {
  color: var(--color-gray-600); /* Gray hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 2. General with Black */
.generalWithBlack .input-field {
  border: 1px solid var(--color-gray-300);
  background-color: var(--Colors-Base-white);
}
.generalWithBlack .input-field input {
  color: var(--color-gray-900); /* Black text color */
}
.generalWithBlack .input-hint {
  color: var(--color-gray-600); /* Gray hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 3. Active Focus */
.activeFocus .input-field {
  border: 1px solid var(--brand-secondary-400);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}

.activeFocus .input-hint {
  color: var(--color-gray-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 4. Disabled */
.disabled .input-field {
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-dark-gray-50);
  color: var(--color-gray-900); /* Black text color */
  cursor: not-allowed;
}
.disabled .input-hint {
  color: var(--color-gray-600); /* paper hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 5. Error with Gray */
.errorWithGray .input-field {
  border: 1px solid var(--danger-300);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-500); /* Gray text color */
}
.errorWithGray .input-hint {
  color: var(--danger-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 6. Error with Black */
.errorWithBlack .input-field {
  border: 1px solid var(--danger-300);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}
.errorWithBlack .input-hint {
  color: var(--danger-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 7. Active Error Focus */
.activeErrorFocus .input-field {
  border: 2px solid var(--danger-500);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}

.activeErrorFocus .input-hint {
  color: var(--danger-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* Icon styles */
.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
