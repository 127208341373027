/*The search bar area*/
.searchComp {
  margin: 0;
  display: flex;
  width: 100%;
  height: 54px;
  margin-top: 24px;
  background-color: white;
  align-items: center;
}

.searchComp p {
  color: var(--text-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  margin-right: 16px;
  margin-left: 16px;
  padding-bottom: 0px;
}
