/* this is the query details will using for 
  1. Shared Saved Hideden and Starred Button
  2. the Shared (read-only) page for other user read
*/
.queryHeaderArea{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 12px 18px;
  background: var(--color-dark-gray-50);
  font-family: var(--font-family-display);
}

.queryDetails{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.queryList{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

/*the query item */
.queryHeaderArea .title{
  color: var(--color-gray-900);
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
}

.queryHeaderArea .subText{
  color: var(--color-gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}

.queryHeaderArea p{
  margin: 0px;
}

.shareTitle{
  color: var(--color-gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
}

.emailText{
  color:#1570EF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
}

