/*the key specification demo css (new) */
.loadingArea{
    height: 100%;
    min-height: calc(100vh - 88px);
    max-height: calc(100vh - 88px);
    display: flex;
    justify-content: center;
    align-items: center;
}

/*the whole area of right data*/
.demoSpecArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

/*the key specification area and standard area*/
.keySpecArea, .standArea{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.keySpecTitle{
    display: flex;
    height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    gap: 6px;
    border-bottom: 2px solid var(--Colors-Border-border-secondary);
    padding-left: 18px;
    padding-right: 12px;
    font-weight: 600;
    font-size: 18px;
}

.standTitle{
    display: flex;
    height: 36px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    gap: 6px;
    border-bottom: 2px solid var(--Colors-Border-border-secondary);
    padding-left: 18px;
    padding-right: 12px;
    font-weight: 600;
    font-size: 18px;
}

.keySpecMenu, .standMenuArea{
  display: flex;
  height: 46px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: var(--color-dark-gray-50);
  margin: 8px 12px;
}

.keySpecMenuList, .standMenuList{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

/* the border line to split the specification area with standard area*/
.borderLine{
  border-top: 2px solid var(--Colors-Border-border-secondary);
}

/*the specification format used to display the data card and accordtion item*/
.demoKeySpecFormat {
  display: flex;
  flex-direction: column;
  height: calc(50vh - 205px);
  min-height: calc(50vh - 205px);
  max-height: calc(50vh - 205px);
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
  cursor: default;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 12px;
  padding-top: 6px;
}

.demoKeySpecFormat::-webkit-scrollbar {
  width: 3px !important;
}

.demoKeySpecFormat::-webkit-scrollbar-track {
  background: white !important;
}

.demoKeySpecFormat::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
  border-radius: 4px;
}