/* The Design System: including fonts family, fonts color, fonts size, icon, kinds of website color*/
:root {
  /* the olo design system - color*/
  --text-color: #424242;
  --Neutral-Foreground-1-Rest: #242424;
  --Neutral-Foreground-3-Rest: #616161;
  --Secondary-color: #066755;
  --icon-color: #707070;
  --line-color: var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
  --button-color: #171a20;

  /* 1. the new design system - fonts & typography*/
  /* Font Families */
  --font-family-display: "Inter", sans-serif;
  --font-family-body: "Inter", sans-serif;

  /* Font Weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Font Sizes */
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-display-xs: 24px;
  --font-size-display-sm: 30px;
  --font-size-display-md: 36px;
  --font-size-display-lg: 48px;
  --font-size-display-xl: 60px;
  --font-size-display-2xl: 72px;

  /* Line Heights */
  --line-height-xxs: 16px;
  --line-height-xs: 18px;
  --line-height-sm: 20px;
  --line-height-md: 24px;
  --line-height-lg: 28px;
  --line-height-xl: 30px;
  --line-height-display-xs: 32px;
  --line-height-display-sm: 38px;
  --line-height-display-md: 44px;
  --line-height-display-lg: 60px;
  --line-height-display-xl: 72px;
  --line-height-display-2xl: 90px;

  /* 2. the new design system - Radius */
  --radius-none: 0;
  --radius-xxs: 2px;
  --radius-xs: 4px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 10px;
  --radius-xl: 12px;
  --radius-2xl: 16px;
  --radius-3xl: 20px;
  --radius-4xl: 24px;
  --radius-full: 9999px;

  /* 3. the new design system - Spacing */
  --space-none: 0;
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-sm: 6px;
  --space-md: 8px;
  --space-lg: 12px;
  --space-xl: 16px;
  --space-2xl: 20px;
  --space-3xl: 24px;
  --space-4xl: 32px;
  --space-5xl: 40px;
  --space-6xl: 48px;
  --space-7xl: 64px;
  --space-8xl: 80px;
  --space-9xl: 96px;
  --space-10xl: 128px;
  --space-11xl: 160px;

  /* 4. the new design system - Widths */
  --width-xxs: 320px;
  --width-xs: 384px;
  --width-sm: 480px;
  --width-md: 560px;
  --width-lg: 640px;
  --width-xl: 768px;
  --width-2xl: 1024px;
  --width-3xl: 1280px;
  --width-4xl: 1440px;
  --width-5xl: 1600px;
  --width-6xl: 1920px;
  --paragaph-mx-width: 720px;

  /* 5. the new design system - Color */
  /* Foundation Colors */
  --color-black: #000000;
  --color-violet: #7747ff;
  --color-indigo: #240a58;
  --color-dark-grey: #282828;
  --color-light-grey: #d1d3d4;

  /* the variables for common use cases */
  --color-primary: var(--color-violet);
  --color-secondary: var(--color-indigo);
  --color-text: var(--color-black);
  --color-background: var(--color-light-grey);
  --color-accent: var(--color-dark-grey);

  /* Base colors */
  --color-white: #ffffff;
  --color-base-white: #fff;
  --color-black: #000000;
  --color-transparent: transparent;
  --Colors-Border-border-secondary: #E4E7EC;

  /* Gray (Light Status) */
  --color-gray-25: #f2f2f2;
  --color-gray-50: #f9f9f9;
  --color-gray-100: #f2f4f7;
  --color-gray-200: #e4e7ec;
  --color-gray-300: #d0d5dd;
  --color-gray-400: #98a2b3;
  --color-gray-500: #667085;
  --color-gray-600: #475467;
  --color-gray-700: #344054;
  --color-gray-800: #282828;
  --color-gray-900: #101828;
  --color-gray-950: #0c111d;

  /* Gray (Dark Status) */
  --color-dark-gray-25: #fcfcfd;
  --color-dark-gray-50: #f9fafb;
  --color-dark-gray-100: #f2f4f7;
  --color-dark-gray-200: #eaecf0;
  --color-dark-gray-300: #d0d5dd;
  --color-dark-gray-400: #98a2b3;
  --color-dark-gray-500: #667085;
  --color-dark-gray-600: #475467;
  --color-dark-gray-700: #344054;
  --color-dark-gray-800: #667085;
  --color-dark-gray-900: #161b26;
  --color-dark-gray-950: #344054;

  /* Brand Colors */
  --brand-25: #f6f4ff;
  --brand-50: #f4f3ff;
  --brand-100: #ebe9fe;
  --brand-200: #d9d6fe;
  --brand-300: #bdb4fe;
  --brand-400: #9b8afb;
  --brand-500: #7a5af8;
  --brand-600: #6938ef;
  --brand-700: #5925dc;
  --brand-800: #4a1fb8;
  --brand-900: #3e1c96;
  --brand-950: #3a1d8c;

  /* Brand Secondary Colors */
  --brand-secondary-25: #f6fefc;
  --brand-secondary-50: #f0fdf9;
  --brand-secondary-100: #ccfbef;
  --brand-secondary-200: #99f6e0;
  --brand-secondary-300: #5fe9d0;
  --brand-secondary-400: #2ed3b7;
  --brand-secondary-500: #15b79e;
  --brand-secondary-600: #0e9384;
  --brand-secondary-700: #107569;
  --brand-secondary-800: #125d56;
  --brand-secondary-900: #134e48;
  --brand-secondary-950: #0a2926;

  /* Danger Colors */
  --danger-25: #fffbfa;
  --danger-50: #fef3f2;
  --danger-100: #fee4e2;
  --danger-200: #fecdca;
  --danger-300: #fda29b;
  --danger-400: #f97066;
  --danger-500: #f04438;
  --danger-600: #d92d20;
  --danger-700: #b42318;
  --danger-800: #912018;
  --danger-900: #7a271a;
  --danger-950: #55160c;

  /* Warning Colors */
  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;
  --warning-950: #4e2009;

  /* Success Colors */
  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;
  --success-950: #033b25;

  --blue-25: #F5FAFF;
  --blue-50: #EFF8FF;
  --blue-100: #D1E9FF;
  --blue-200: #B2DDFF;
  --blue-300: #84CAFF;
  --blue-400: #53B1FD;
  --blue-500: #2E90FA;
  --blue-600: #1570EF;
  --blue-700: #175CD3;
  --blue-800: #1849A9;
  --blue-900: #194185;
  --blue-950: #102A56;
}
