/****************** 3. the print modal and attrubite **********************/
/*the custom modal to display error search including empty and can not find, background*/
.printModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none; /* Makes the modal container not block clicks */
}

/*the custom modal to display error search including empty and can not find, whithe modal window*/
.printModal-content {
  background: var(--Neutral-Background-1-Rest, #fff);
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 16px;
  border: 1px solid
    var(--Neutral-Stroke-Transparent-Rest, rgba(255, 255, 255, 0));
  width: 30vw;
  max-width: 620px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-height: 80vh;
  overflow-y: hidden;
  border-radius: var(--8, 8px);
  box-shadow:
    0px 32px 64px 0px rgba(0, 0, 0, 0.24),
    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  pointer-events: all; /* This allows interactions within the pop-up */
  cursor: grab; /* Indicates it's draggable */
}

.printSelectionTittle {
  display: flex;
  padding-left: 8px;
  margin-top: 16px;
  align-self: stretch;
  height: 32px;
  min-height: 32px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
}

.printSelectionTittle label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-left: 28px;
  color: var(--Neutral-Foreground-3-Rest);
}

.printModal h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--Neutral-Foreground-1-Rest);
}

.printSelectionItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  min-height: 32px;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
}

/*the cancel button in the right-top */
.printModal .cancelButton {
  position: absolute;
  top: 10px;
  right: 11px;
  cursor: pointer;
}

.printModal-content span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 24px;
  color: var(--Neutral-Foreground-1-Rest);
}

.printSelectionArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 36px;
  color: var(--text-color);
  height: 100%;
  align-self: stretch;
  max-height: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.printSelectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-left: 8px;
  color: var(--Neutral-Foreground-1-Rest);
  font-size: 14px;
  height: 32px;
  min-height: 32px;
  border-bottom: 1px solid var(--Neutral-Stroke-Subtle-Rest, #e0e0e0);
}

.printSelectionContext {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* default is 2 column */
  align-items: start;
  align-self: stretch;

  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 12px;
  font-size: 12px;
}

.printSelectionContext .smallSelectionItem {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--Neutral-Foreground-1-Rest);
}

.printSelectionTittle.active {
  background-color: var(--Neutral-Background-2-Rest, #fff);
}

.printSelectionContext.active {
  background: var(--Neutral-Background-2-Rest, #fafafa);
  font-size: 12px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
  align-self: stretch;
}

/*the confirm button in the modal window*/
.printModal-content .print-button {
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: var(--Medium, 4px);
  background: var(--Secondary-color);
  color: white;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 16px;
}

.printModal-content .print-button:active {
  background-color: #044f41; /* Even darker shade on click */
  transform: scale(0.95); /* Shrink slightly on click */
}

.printModal-content .cancel-button {
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: var(--Medium, 4px);
  border: 1px solid var(--Neutral-Stroke-1-Rest, #d1d1d1);
  background: var(--Neutral-Background-1-Rest, #fff);
  font-weight: bold;
  margin-top: 16px;
}

.printModal-content .cancel-button:active {
  background-color: #e0e0e0; /* Darker background on click */
  transform: scale(0.95); /* Slightly shrink the button on click */
}
