/**************** 1. The node view graph area including filter bar and bottom bar check color *************************************/
/*the filter bar area*/
.rectangle-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #ccc;
}

/*the node graph area*/
.nodeGraph {
  width: 100%;
  height: 100%;
}

/*the graph bottom feature part: check color view and scoll bar */
.graphBot {
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
  background-color: white;
  border-top: 1px solid #ccc;
}

.graphBot label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: var(--text-color);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/*search bar area: Item Node views attributes to change node color view*/
.graphBot input[type="radio"] {
  appearance: none;
  /*not allowed use default colors*/
  -webkit-appearance: none;
  /* ==> webkit chrome */
  -moz-appearance: none;
  /* ==> firfox web */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 6px;
  border: 1px solid #616161;
}

.graphBot input[type="radio"]:checked {
  background-color: #066755;
}

/*the scroll bar in the graph bottom area*/
.slider-container {
  display: flex;
  align-items: center;
}

.slider {
  width: 120px;
  height: 2px;
  background-color: #ccc;
  border-radius: 5px;
  appearance: none;
  /*not allowed use default colors*/
  -webkit-appearance: none;
  /* ==> webkit chrome */
  -moz-appearance: none;
  /* ==> firfox web */
  background-image: linear-gradient(
    to right,
    green 0%,
    green calc(50% - 10px),
    #ccc calc(50% - 10px),
    #ccc 100%
  );
  /*left is green, right is gray*/
}

.slider::-webkit-slider-thumb {
  appearance: none;
  /*not allowed use default colors*/
  -webkit-appearance: none;
  -webkit-appearance: none;
  /* ==> webkit chrome */
  width: 10px;
  height: 10px;
  background-color: #066755;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 0px;
}

.slider-value {
  margin-left: 10px;
}

/*the filter bar: function, system, sub-system feature*/
.dropdown-container {
  display: flex;
  margin-left: 0;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.dropdown-container .ms-Dropdown {
  background-color: white;
  border: none;
}

/*the filter bar: 2D node graph*/
.button-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 12px;
  align-items: flex-start;
}

.button-container button {
  background: var(
    --Neutral-Background-Transparent-Rest,
    rgba(255, 255, 255, 0)
  );
  color: var(--text-color);
  border: none;
  padding-bottom: 6px;
  font-size: 13px;
  margin-top: 0px;
  height: 36px;
  margin-left: 8px;
}

.button-container button:hover {
  font-weight: bold;
  border-bottom: 2px solid #066755;
}

.button-container button.active {
  color: #066755;
  font-weight: bold;
  border-bottom: 2px solid #066755;
}

/*the color bar of the 2D graph for user selected the node and open Properties area details*/
.color-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 8px;
  margin-bottom: 8px;
  background-color: white;
  margin-left: 16px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid var(--Neutral-Background-Disabled-Rest, #f0f0f0);
  z-index: 999;
  opacity: 100%;
}

.color-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.color-block {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.color-label {
  font-size: 8px;
  white-space: nowrap;
  color: var(--text-color);
}
