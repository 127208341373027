.warning-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background:  var(--Colors-Alpha-alpha-black-80, rgba(0, 0, 0, 0.80));; /* the background change to dark */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* make sure this will display on the top*/
}

/*the whith notification area display the informaton to notice user increase width*/
.warning-message {
  background-color: #fff;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  border-radius: var(--radius-xs);
  font-family: var(--font-family-display);
}

/*the red incrase icon*/
.warning-message .IncreaseIcon {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full);
  background: var(--danger-100);
}

/*the tittle*/
.warning-message h2{
  font-size: var(--font-size-lg);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(---line-height-lg); /* 155.556% */
  color: var(--color-gray-900);
  margin: 0px;
  margin-bottom: 4px;
}

/*the context text*/
.warning-message p{
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
  color: var(--color-gray-600);
  margin: 0px;
}