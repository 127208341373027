.ErrorPage{
    display: flex;
    width: 100vw;
    height: 90vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background:#FFF;
    font-family: var(--font-family-display);
}

.textArea{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 48px;
}

.redError{
    color: var(--danger-600);
    text-align: center;
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md); 
}

.tittleError{
    color: var(--color-gray-950);
    text-align: center;
    font-size: var(--font-size-display-xl);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-display-xl); 
    margin-bottom: 12px;
}

.contextError{
    color: var(--color-gray-800);
    text-align: center;
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-size-display-sm);    
}

.buttonArray{
    display: flex;
 
    justify-content: center;
    align-items: center;
    margin-bottom: 96px;
    gap: 12px;
}

.cardArray{
    display: flex;
    align-items: center;
    padding: 0px 32px;
    gap: 32px
}