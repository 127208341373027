.IconArea{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 9999px);
}

/*the size of this icon */
.IconArea--sm{
    width: 16px;
    height: 16px;
    padding: 8px;
}

.IconArea--sm svg{
    width: 16px;
    height: 16px;
}

.IconArea--md{
    width: 20px;
    height: 20px;
    padding: 10px;
}

.IconArea--md svg{
    width: 20px;
    height: 20px;
}

.IconArea--lg{
    width: 24px;
    height: 24px;
    padding: 12px;
}

.IconArea--lg svg{
    width: 24px;
    height: 24px;
}

.IconArea--xl{
    width: 26px;
    height: 26px;
    padding: 15px;
}

.IconArea--xl svg{
    width: 28px;
    height: 28px;
}

/*the icon area color */
.IconArea--green{
    background: var(--brand-secondary-100);
}

.IconArea--gray{
    background: var(--color-gray-100);
}

.IconArea--red{
    background: var(--danger-100);
}

.IconArea--yellow{
    background: var(--warning-100);
}

.IconArea--darkGreen{
    background: #DCFAE6;
}


