.SwitchImage{
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: #F2F4F7;
    font-family: var(--font-family-display);
}

.switchImageContainer {
  position: relative;
  margin: 0 auto;
  text-align: center;
  background: #fff;
}

/*the image display UI should in the contain of switchImageContainer*/
.imageWrapper {
  position: relative;
  overflow: hidden;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.switchImageContainer .displayImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  user-select: none; /* not allowed could click to dragging the image */
  -webkit-user-drag: none;  /* not allowed could click to dragging the image */
}

/* the left and right wrapper area */
.buttonWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.leftButton {
  left: 10px;
  transform: translate(-50%, -50%);
}

.rightButton {
  right: 10px;
  transform: translate(50%, -50%);
}

/* the bottom pagenavigation */
.pagination {
  display: flex;
  position: absolute;
  bottom: 24px;
  left: 45%;
  justify-content: center;
  gap: 12px;
  padding: 8px;
  border-radius: 12px;
  background: #fff;
  /* Backdrop blurs/backdrop-blur-sm */
  backdrop-filter: blur(4px);
  border: 1px solid #f2f4f7;
}

.paginationDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #E4E7EC;
  transition: background-color 0.3s ease;
}

.paginationDot.active {
  background-color: var(--brand-secondary-400);
}

.imageTitleText{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-xs); /* 150% */
    padding: 4px 12px;
    color:#101828;
    border-radius: 0px 0px 4px 4px;
}
