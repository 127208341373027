.container{
  display: flex;
  width: 100vw;
  height: calc(100vh - 24px);
  padding: 24px 0px;
}

.forgotPasswordComp{
  width: calc(50% - 16px);
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.forgotHeader h2 {
  margin-top: 0;
  text-align: center;
  font-size: var(--font-size-display-sm);
  color: var(--color-gray-900);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-display-sm);
  margin-bottom: var(--font-size-xs);
}

.forgotHeader p {
  margin-top: 0;
  font-size: 13px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-gray-700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
  margin-bottom: var(--font-size-xs);
  white-space: normal; /* 允许文本换行 */
}

/* 2. the forgot password component ==> input account email css */
.forgetPasswordArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 360px;
  white-space: nowrap;
  gap: 18px;
}

.jumpText{
  color: var(--blue-600);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-sm); /* 150% */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;  
}

.jumpText:active{
  color: var(--blue-700);
}

.jumpText:hover{
  color: var(--blue-700);
}



.image-container {
  display: flex;
  width: calc(50vw - 24px);
  height: calc(100% - 24px);
  justify-content: center;
  align-items: center;
  border-radius: var(--Font-height-text-xxs, 16px);
  background-image: url('../../../image/Background.png');
  background-size: cover;
  user-select: none; /* Prevents text selection */
}

.image {
  position: absolute;
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;  /* the hover effecr */
}

/* the position of every image */
.imageBig {
  width: 100%;
  height: 90vh;
  object-fit: contain;
  pointer-events: none;
}


