.defaultArea {
  flex: 1;
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
  width: 100%;
  padding: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-display);
}

.iconWithText{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 10px;
   width: 65%;
}

.defaultArea .svgIcon{
  width: 48px;
  height: 48px;
  display: flex;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.titleStyle{
  color: var(--color-dark-gray-900);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md); /* 150% */
}

.contextStyle{
  color: var(--color-gray-600);
  text-align: center;
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm); /* 150% */
}



