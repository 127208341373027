/*the side bar area */
.sidebar {
  min-height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  padding: 0 8px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.4s ease;
  border-radius: 4px;
  background: #FCFCFD;
  align-self: stretch;
  font-family: var(--font-family-display);
  border-right: 1px solid #E4E7EC;
  width: 240px;
}

.sidebar::-webkit-scrollbar {
  width: 4px !important;
}

.sidebar::-webkit-scrollbar-track {
  background: white !important;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}


/*the side bar open status */
.sidebar.open {
  width: 240px;
}

/*the side bar closed status */
.sidebar.closed {
  width: 52px;
}

/*********************************  1. the achieve module side bar  *********************************/
/*the side bar icon: control open or closed sideBar */
.sidebar-toggle-icon {
  margin-top: 20px;
  margin-left: 5px;
  width: 28px;
  height: 28px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent; 
}

.sidebar-toggle-icon:hover{
  background-color: #F0FDF9;
  border-color: #2ED3B7;
  cursor: pointer;
}

/*the side bar item area*/
.sidebar-items {
  margin-top: 35px;
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.sidebar-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: padding 0.3s ease;
  border: 1px solid transparent; /* add the transparent border*/
}

.itemIcon{
  width: 24px;
  height: 24px;
}

.sidebar-item .item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-sm); /* 142.857% */
  color: var(--color-gray-500);
}

.sidebar-item:hover {
  background-color: #F0FDF9;
  border-color: #2ED3B7;
  cursor: pointer;
}

/*********************************  2. the discover module side bar  *********************************/
/* History list styles */
.history-list {
  margin-top: 0px;
}

.history-section {
  margin-bottom: 8px;
  padding: 8px 0px;
}

.history-title {
  font-size: var(--font-size-sm);
  font-weight: 600;
  color: var(--color-gray-400); 
  margin-bottom: 8px;
  margin-left: 12px;
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-xxs); /* 160% */
}

.history-items {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.history-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  transition: padding 0.3s ease;
  border: 1px solid transparent; /* add the transparent border*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-sm); /* 142.857% */
  color: var(--color-gray-500);
}

.history-item:hover {
  background-color: #F0FDF9;
  border-color: #2ED3B7;
  cursor: pointer;
}
