/* The Chart Card used for achieve modul all the chart */
.ChartCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family-display);
    border: 1px solid var(--colors-gray-light-mode-100, #F2F4F7);;
}

/* dropdown menu with View Details button */
.chartMenuArea{
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #F2F4F7;
}

.detailsText{
    color: var(--blue-600);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    cursor: pointer;
}

.chartArea{
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    align-items: flex-start;
    border-radius: 0px 0px 4px 4px;
    background: var(--color-base-white);

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
}

.chartDataDeatils{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

