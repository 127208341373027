@import "../../../App.css";

.tooltip-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.tooltip-wrapper svg {
  color: var(--color-gray-400);
}

.tooltip-wrapper svg:hover {
  color: var(--color-gray-500);
}

.tooltip {
  position: absolute;
  z-index: 10000;
  padding: var(--space-md) var(--space-lg);
  border-radius: var(--radius-md);
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  color: var(--color-gray-700);
  white-space: normal;
  max-width: 285px;
  max-height: auto;
  word-wrap: break-word;
  transition: opacity 0.2s ease;
}

.tooltip h2 {
  font-weight: var(--font-weight-semibold);
  margin: 0px;
}

.tooltip p {
  font-weight: var(--font-weight-medium);
  margin-top: 4px;
  margin-bottom: 0px;
}

.tooltip-black {
  color: var(--color-base-white);
  background-color: var(--color-gray-900);
}

.tooltip-white {
  color: var(--color-gray-700);
  background-color: var(--color-base-white);
}

.tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

/* Arrow for black background */
.tooltip-black.tooltip-bottomCenter::after {
  left: 50%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent; /* Arrow color matches black background */
  transform: translateX(-50%);
}

.tooltip-black.tooltip-bottomLeft::after {
  left: 5%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent; /* Arrow color matches black background */
  transform: translateX(-50%);
}

.tooltip-black.tooltip-bottomRight::after {
  left: 95%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent; /* Arrow color matches black background */
  transform: translateX(-50%);
}

.tooltip-black.tooltip-default::after {
  display: none;
}

.tooltip-black.tooltip-left::after {
  top: 50%;
  left: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #333 transparent transparent;
  transform: translateY(-50%);
}

.tooltip-black.tooltip-right::after {
  top: 50%;
  right: -5px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #333;
  transform: translateY(-50%);
}

.tooltip-black.tooltip-top::after {
  left: 50%;
  bottom: 100%;
  border-width: 0 5px 5px;
  border-color: transparent transparent #333 transparent;
  transform: translateX(-50%);
}

/* Arrow for white background */
.tooltip-white.tooltip-bottomCenter::after {
  left: 50%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #fff transparent transparent transparent; /* Arrow color matches white background */
  transform: translateX(-50%);
}

.tooltip-white.tooltip-bottomLeft::after {
  left: 5%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #fff transparent transparent transparent; /* Arrow color matches white background */
  transform: translateX(-50%);
}

.tooltip-white.tooltip-bottomRight::after {
  left: 95%;
  top: 100%;
  border-width: 5px 5px 0;
  border-color: #fff transparent transparent transparent; /* Arrow color matches white background */
  transform: translateX(-50%);
}

.tooltip-white.tooltip-default::after {
  display: none;
}

.tooltip-white.tooltip-left::after {
  top: 50%;
  left: -5px;
  border-width: 5px 5px 5px 0;
  border-color: transparent #fff transparent transparent;
  transform: translateY(-50%);
}

.tooltip-white.tooltip-right::after {
  top: 50%;
  right: -5px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #fff;
  transform: translateY(-50%);
}

.tooltip-white.tooltip-top::after {
  left: 50%;
  bottom: 100%;
  border-width: 0 5px 5px;
  border-color: transparent transparent #fff transparent;
  transform: translateX(-50%);
}
