.successNoticeArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 24px;
    font-family: var(--font-family-display);
}

.titleTextArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title{
    color: var(--color-gray-900);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xl); /* 150% */
}

.subTitle{
    color: var(--color-gray-700);
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-md); /* 150% */
}

.jumpText{
    color: var(--blue-600);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm); /* 150% */
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}


