@import "../../../App.css";

.toggle-switch {
  display: inline-block;
  width: 50px;
  height: 28px;
  background-color: var(--color-dark-gray-100);
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-switch.open {
  background-color: var(--color-gray-700);
}

.toggle-switch .toggle-handle {
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.toggle-switch.open .toggle-handle {
  transform: translateX(22px);
}

.toggle-switch.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Hover effects */
.toggle-switch:hover {
  background-color: var(--color-dark-gray-100);
}

.toggle-switch.open:hover {
  background-color: var(
    --color-gray-800
  ); /* Darker green for hover on 'on' state */
}
