@import "../../../App.css";

/* General Styles */
.textarea-container {
  margin-bottom: 1.5rem;
  font-family: var(--font-family-display); /* Font family */
}

.textarea-label {
  font-size: var(--font-size-xs); /* Font size */
  color: var(--color-dark-gray-700);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xs); /* Line height */
}

.textareaField {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffff !important;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 8px 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow-y: hidden; /* Ensure that overflow is hidden */
  font-family: var(--font-family-display);
  font-size: var(--font-size-xs); /* Font size */
  font-style: normal; /* Font style */
  font-weight: var(--font-weight-regular); /* Font weight */
  line-height: var(--line-height-xs); /* Line height */
}

.textareaField textarea {
  width: auto;
  min-width: 40%;
  flex: 1;
  border: none;
  outline: none;
  background-color: #ffff !important;
  font-family: var(--font-family-body) !important;
  font-size: var(--font-size-xs); /* Font size */
  font-style: normal; /* Font style */
  font-weight: var(--font-weight-regular); /* Font weight */
  line-height: var(--line-height-xs); /* Line height */
}

/*the scroll bar*/
textarea::-webkit-scrollbar {
  width: 1px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: white !important;
  cursor: pointer;
  border-radius: 1px;
}

textarea::-webkit-scrollbar-track {
  background-color: white !important;
}

/* 1. General with Black */
.generalWithBlack .textareaField {
  border: 1px solid var(--color-gray-300);
  background-color: var(--Colors-Base-white);
}
.generalWithBlack .textareaField input {
  color: var(--color-gray-900); /* Black text color */
}
.generalWithBlack .input-hint {
  color: var(--color-gray-600); /* Gray hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 2. Active Focus */
.activeFocus .textareaField {
  border: 2px solid var(--brand-secondary-400);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}

.activeFocus .input-hint {
  color: var(--color-gray-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 3. Disabled */
.disabled .textareaField {
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-dark-gray-50);
  color: var(--color-gray-900); /* Black text color */
  cursor: not-allowed;
}
.disabled .input-hint {
  color: var(--color-gray-600); /* paper hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 4. Error with Black */
.errorWithBlack .textareaField {
  border: 1px solid var(--danger-300);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}
.errorWithBlack .input-hint {
  color: var(--danger-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* 5. Active Error Focus */
.activeErrorFocus .textareaField {
  border: 2px solid var(--danger-500);
  background-color: var(--Colors-Base-white);
  color: var(--color-gray-900); /* Black text color */
}
.activeErrorFocus .input-hint {
  color: var(--danger-600); /* Blue hint color */
  font-size: var(--font-size-xs);
  margin: 0px;
}

/* Icon styles */
.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-input-area {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px; /*the space */
}
