/* the overview card component used for display the overviw of user current searched issue id*/
.OverviewCard{
    display: flex;
    position: absolute;
    top: 46px;
    left: 220px;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-base-white);
    /* Shadows/shadow-lg */
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    font-family: var(--font-family-display);
    z-index: 999;
}

.OverviewContext{
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: pre-wrap; /* Preserves line breaks in the content */
    background: var(--color-dark-gray-50);
}

.OverviewContext::-webkit-scrollbar {
  width: 2px !important;
}

.OverviewContext::-webkit-scrollbar-track {
  background: white !important;
}

.OverviewContext::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
  border-radius: 4px;
}

.OverviewContext .overviewTitle{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 700;
    line-height: var(--line-height-sm); /* 142.857% */
    color: #182230;
}

.OverviewContext .overviewText{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-sm); /* 142.857% */
    color: #182230;
}

.OverviewContext .issueLink {
    color: var(--blue-600);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.OverviewContext .issueLink:hover {
    color: var(--blue-700);
}

.overViewBottomText{
    font-size: var(--font-size-xs);
    font-style: italic; /*the italic;*/
    font-weight: 400;
    line-height: var(--line-height-xxs); /* 142.857% */
    color: #182230;
    text-align: center;
}
